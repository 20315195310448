/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:7c1faa7f-cfc2-49c5-93a6-56ce0a3948b1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_eYyyAMs2y",
    "aws_user_pools_web_client_id": "f07eteoj1m3qsjtbmde4v3hg6",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://knukqxayd5d4vghrmyjttf2blq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-n2edda6xmngcnczzsvpfxwmnne",
    "aws_cloud_logic_custom": [
        {
            "name": "btpy",
            "endpoint": "https://iq614kl7od.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
