import React from "react";
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Notes from './components/notes'
import Docs from './components/docs'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';

function App() {
  return (
    <Router>
      <div>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">Black Tusk Geophysics</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="http://www.btgeophysics.com/">BTG</Nav.Link>
              <NavDropdown title="Menu" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://www.blacktuskgeo.com/btdocs/">Docs</NavDropdown.Item>
                <NavDropdown.Item href="/notes">Notes</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/users">Users</NavDropdown.Item>
              </NavDropdown>
                <AmplifySignOut />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/docs">
            <Docs />
          </Route>
          <Route path="/notes">
            <Notes />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

export default withAuthenticator(App);