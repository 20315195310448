import React, { useState, useEffect } from 'react';
import '../App.css';
import { API } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { listNotes } from '../graphql/queries';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from '../graphql/mutations';

const initialFormState = { name: '', description: '' }

function Docs() {

  return (
    <div className="Docs">
      <h1>My Docs</h1>

    </div>
  );
}

export default withAuthenticator(Docs);